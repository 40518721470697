//
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from "../../../globalActions/index";

export const getInvoiceCount = function () {
    const docRef = db.doc("order_number_counter/VVr0gV8WMDdzHNwkKQSe");
    docRef.onSnapshot((doc) => {
        this.count = doc.data().invoice_count;
    })
}
/*
    Loop of selected sales orders
    1)  Save invoice to database
*/
export const createBulkInvoices = function () {
    let counter = this.count
    let incrementCount = 0
    this.loading = true
    const batch = db.batch()
    let uniqueDatesArray = [];
    // 1.   Save invoice to database
    const saveInvoiceToDB = (order) => {
        const invoiceRef = db.collection("invoices").doc()
        const entryRef = db.collection("general_journal_entries").doc();
        batch.set(invoiceRef, {
            invoice_id: invoiceRef.id,
            invoice_number: counter < 1000
                ? `INV-${String("00000" + counter).slice(-4)}`
                : `INV-${counter}`,
            invoice_issue_date: order.order_date,
            invoice_due_date: order.order_date,
            sales_order_ref: order.order_number,
            sales_order_id: order.order_id,
            quote_ref: order.quote_ref ? order.quote_ref : null,
            customer: order.customer,
            line_items: order.line_items,
            order_subtotal: order.order_subtotal,
            order_discount: order.order_discount,
            order_discounted_subtotal: order.order_discounted_subtotal,
            order_delivery_fee: order.order_delivery_fee,
            invoice_total: order.order_value,
            invoice_amount_paid: order.order_amount_paid
                ? order.order_amount_paid
                : 0,
            invoice_amount_due: order.order_amount_due
                ? order.order_amount_due
                : order.order_value,
            // Links allocated payments && GJE id to the invoice
            // (additional steps further down in this code)
            customer_payments: order.customer_payments
                ? order.customer_payments
                : [],
            entry_id: entryRef.id,
        })
        //  Add invoice ID to sales order
        const updateSalesOrderInfo = () => {
            const salesRef = db.collection("sales_orders").doc(order.order_id)
            batch.update(salesRef, {
                invoice_id: invoiceRef.id,
                invoice_number: counter < 1000
                    ? `INV-${String("00000" + counter).slice(-4)}`
                    : `INV-${counter}`,
            })
        }
        updateSalesOrderInfo()
        // ACCOUNTING LOGIC
        //  Create Journal entry to record changes in respective ledgers
        //  Calculate Down Payments Contra
        const calcContraAmount = () => {
            const paymentRef = order.customer_payments;
            const contraAmount = paymentRef
                ? paymentRef.reduce((total, el) => total + el.payment_amount, 0)
                : 0;
            return contraAmount;
        };
        const createJournalEntry = () => {
            //  Revenue
            const revenueObj = {
                entry_amount: order.order_value,
                line_amount: order.order_value,
                ledger_account: {
                    account_id: "qQGkDkuO9msttN4IFB1z",
                    account_name: "Revenue",
                },
            };
            //  Accounts Receivable
            const receivablesObj = {
                entry_amount: order.order_amount_due,
                line_amount: order.order_amount_due,
                ledger_account: {
                    account_id: "Zb7fNd7tfFmOntoUcUUt",
                    account_name: "Accounts Receivable",
                },
            };
            //   Down Payments Contra
            const downPaymentsContraObj = {
                entry_amount: calcContraAmount(),
                line_amount: calcContraAmount(),
                ledger_account: {
                    account_id: "BHlsHkQ5SXkPDSSK5Vh2",
                    account_name: "Customer Down Payments Contra",
                },
            };
            const lineItems = [revenueObj];
            if (order.order_amount_due !== 0) lineItems.push(receivablesObj);
            if (order.customer_payments) lineItems.push(downPaymentsContraObj);
            // GJE
            const GJEObj = {
                journal_entry_id: entryRef.id,
                journal_entry_date: order.order_date,
                journal_entry_number: this.formatJournalEntryNumber(),
                closed_off: false,
                line_items: lineItems,
                reference_number: counter < 1000
                    ? `INV-${String("00000" + counter).slice(-4)}`
                    : `INV-${counter}`,
                invoice_id: invoiceRef.id,
                invoice_number: counter < 1000
                    ? `INV-${String("00000" + counter).slice(-4)}`
                    : `INV-${counter}`,
            };

            batch.set(entryRef, { ...GJEObj });
            //  Update journal entry count to keep entry numbers in sync with database
            this.journal_entry_count++
        };
        createJournalEntry()
    }// END OF LOOP
    //    Update Accounting Totals
    const updateLedgerTotals = () => {
        //
        const getUniqueMonths = () => {
            const dateSet = new Set(this.selected_orders.map((order) => order.order_date.slice(0, 7)))
            return uniqueDatesArray = Array.from(dateSet)
        }
        //
        const calculateLedgerTotals = () => {
            uniqueDatesArray.forEach((date) => {
                // Calculate revenue per month
                const salesPerMonth = this.selected_orders
                    .filter((order) => order.order_date.slice(0, 7) === date)
                const revenuePerMonth =
                    salesPerMonth.reduce((total, item) => total + item.order_value, 0)
                // Increase revenue
                const salesLedgerRef = db.doc(`accounting_totals/${date}/ledger_accounts/qQGkDkuO9msttN4IFB1z`)
                batch.update(salesLedgerRef, {
                    monthly_total: firebase.firestore.FieldValue.increment(revenuePerMonth)
                })
                //  Test if sales had prior payments
                //  If NO --> increase "Accounts Receivable"
                //  If YES --> increase "Down Payments Contra" && "Accounts Receivable"
                const salesWithPayment = salesPerMonth.filter((order) => order.customer_payments && order.customer_payments.length > 0)
                const contraAmount = salesWithPayment.length === 0 ? 0 : salesWithPayment.reduce((total, item) => total + item.order_amount_paid, 0)
                const testForPriorPayments = () => {
                    if (contraAmount === 0) return;
                    //Increase "Down Payments Contra"
                    const contraRef = db.doc(`accounting_totals/${date}/ledger_accounts/BHlsHkQ5SXkPDSSK5Vh2`)
                    batch.update(contraRef, {
                        monthly_total: firebase.firestore.FieldValue.increment(contraAmount)
                    })
                }
                testForPriorPayments()
                // Increase accounts receivable
                const receivableRef = db.doc(`accounting_totals/${date}/ledger_accounts/Zb7fNd7tfFmOntoUcUUt`)
                const receivableAmount = revenuePerMonth - contraAmount
                batch.update(receivableRef, {
                    monthly_total: firebase.firestore.FieldValue.increment(receivableAmount)
                })
            })
        }
        getUniqueMonths()
        calculateLedgerTotals()
    }
    const updateCounters = () => {
        // update invoice_count in database
        const invoiceCountRef = db.doc("order_number_counter/VVr0gV8WMDdzHNwkKQSe");
        batch.update(invoiceCountRef, {
            invoice_count: firebase.firestore.FieldValue.increment(incrementCount)
        })
        // update journal entry count
        const GJECountRef = db.doc("general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2")
        batch.update(GJECountRef, {
            journal_entry_count: firebase.firestore.FieldValue.increment(incrementCount)
        })
    }
    // 1
    updateLedgerTotals()
    // 3
    this.selected_orders.forEach((order) => {
        saveInvoiceToDB(order)
        counter++
        incrementCount++
    })
    // 4
    updateCounters()
    // 5
    batch.commit()
        .then(() => {
            this.loading = false
            showSnackbar(`${this.selected_orders.length} New Invoices Created`)
            this.$emit("closeBulkInvoiceModal")
        })
        .catch((error) => error)
}

// Used to populate payment allocation data table
export const getBankTransactions = function () {
    this.transactions = [];
    this.loadingTransactions = true
    const docRef = db
        .collection("bank_transactions")
        .where("bank_account_id", "==", this.bankAccount)
        .where("transaction_type", "==", "Cash In")
        .where("transaction_allocation_status", "==", "No");

    docRef.onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            /*
              Predicts which transactions are most likely related to the selected Invoice
              based on a number of factors
              Related transactions can then be filtered in the UI
            */
            //  Transaction Amount
            const transactionAmountMatch = () =>
                data.transaction_amount === this.invoice.invoice_total;
            // 50% Deposit
            const depositMatch = () =>
                data.transaction_amount === this.invoice.invoice_total * 0.5;
            //  Sales order #
            const salesOrderMatch = () =>
                !!data.transaction_description &&
                data.transaction_description.includes(
                    this.invoice.sales_order_ref.slice(
                        3,
                        this.invoice.sales_order_ref.length
                    )
                ) &&
                data.transaction_description.includes("SO");
            // Quote #
            const quoteMatch = () =>
                !!data.transaction_description &&
                !!this.invoice.quote_ref &&
                data.transaction_description.includes(
                    this.invoice.quote_ref.slice(3, this.invoice.quote_ref.length)
                ) &&
                data.transaction_description.includes("QU");
            // INV #
            const invoiceMatch = () =>
                !!data.transaction_description &&
                data.transaction_description.includes(this.invoice.invoice_number);
            // Customer Name
            const customerNameMatch = () =>
                !!data.transaction_description &&
                this.invoice.customer.customer_name
                    .split(" ")
                    .some(
                        (element) =>
                            data.transaction_description.includes(element) &&
                            element !== "-"
                    );
            // Customer Cell
            const customerCellMatch = () =>
                !!data.transaction_description &&
                !!this.invoice.customer.customer_cell &&
                data.transaction_description.includes(
                    this.invoice.customer.customer_cell.replace(/-|\s/g, "")
                );
            const calcRecommendedTransaction = () => {
                return (
                    transactionAmountMatch() ||
                    depositMatch() ||
                    salesOrderMatch() ||
                    quoteMatch() ||
                    invoiceMatch() ||
                    customerNameMatch() ||
                    customerCellMatch()
                );
            };
            let weight = 0;
            if (transactionAmountMatch() === true) weight++;
            if (depositMatch() === true) weight++;
            if (salesOrderMatch() === true) weight++;
            if (quoteMatch() === true) weight++;
            if (invoiceMatch() === true) weight++;
            if (customerNameMatch() === true) weight++;
            if (customerCellMatch() === true) weight++;

            calcRecommendedTransaction()
                ? this.transactions.push({
                    ...data,
                    shortcut: true,
                    weight: weight,
                })
                : this.transactions.push(data);
        });
        this.loadingTransactions = false;
    });
}

export const getCustomerPayments = function (invoice) {
    this.loadingPayments = true;
    const paymentIDs = invoice.customer_payments.map(
        (item) => item.payment_id
    );
    paymentIDs.forEach((id) => {
        const paymentRef = db.doc(`customer_payments/${id}`);
        paymentRef.onSnapshot((doc) => {
            this.customer_payments = [];
            const data = doc.data();
            this.customer_payments.push(data);
            this.loadingPayments = false;
        });
    });
}

/*
        EXPLANATION:
        -   A customer payment record is created for each transaction allocated
            to the invoice
        -   Each payment is then linked to its corresponding bank transaction
        -   A journal entry is created to update the "Suspense" and "Accounts Receivable"
            ledgers via their contras
        -   Bank transaction is added to the GJE
        -   Update "Accounting Totals" sub-collection for each line-item
        -   The invoice is updated (customer payments linked, amounts paid and due updated)
*/
//
export const getPaymentCount = function () {
    const docRef = db.doc("customer_payment_counter/uDlDYQCHnCW03mhjtGla");
    docRef.onSnapshot((doc) => {
        this.count = doc.data().count;
    });
}
//
export const getJournalEntryCount = function () {
    db.collection("general_journal_entry_counter")
        .doc("LVEzU1bhljJ8ZLLz6pC2")
        .onSnapshot((snapshot) => {
            this.journal_entry_count = snapshot.data().journal_entry_count;
        });
}
//
export const formatPaymentNumber = function () {
    return this.count < 1000
        ? `CPA-${String("00000" + this.count).slice(-4)}`
        : `CPA-${this.count}`;
}
//
export const formatJournalEntryNumber = function () {
    return this.journal_entry_count < 1000
        ? `GJE-${String("00000" + this.journal_entry_count).slice(-4)}`
        : `GJE-${this.journal_entry_count}`;
}
//
export const incrementPaymentCount = function () {
    const docRef = db.doc("customer_payment_counter/uDlDYQCHnCW03mhjtGla");
    docRef.update({ count: this.count });
}
//
export const incrementJournalEntryCount = function () {
    const docRef = db.doc(
        "general_journal_entry_counter/LVEzU1bhljJ8ZLLz6pC2"
    );
    docRef.update({ journal_entry_count: this.journal_entry_count });
}
//
export const allocatePaymentToInvoice = function () {
    this.loading = true;
    const customerPaymentsArray = [];
    const invoiceRef = db.doc(`invoices/${this.invoice.invoice_id}`);
    // 1. Create customer payment for each transaction
    const createReceipt = () => {
        const batch = db.batch();
        this.selectedTransactions.forEach((transaction) => {
            const entryRef = db.collection("general_journal_entries").doc();
            const paymentRef = db.collection("customer_payments").doc();
            // Contains all payment related info
            const paymentObj = {
                payment_id: paymentRef.id,
                payment_number: this.formatPaymentNumber(),
                payment_date: transaction.transaction_date,
                payment_amount: transaction.transaction_amount,
            };

            batch.set(paymentRef, {
                ...paymentObj,
                customer: this.invoice.customer,
                invoices: [
                    {
                        invoice_number: this.invoice.invoice_number,
                        invoice_id: this.invoice.invoice_id,
                        invoice_total: this.invoice.invoice_total,
                    },
                ],
                transaction_id: transaction.transaction_id,
                transaction_number: transaction.transaction_number,
                //  Add journal entry id to payment so that it can be accessed
                //  in the event that the payment is deleted
                entry_id: entryRef.id,
            });
            customerPaymentsArray.push(paymentObj);
            // 2. Link payment to bank transactions
            const updateBankTransaction = () => {
                const transactionRef = db.doc(
                    `bank_transactions/${transaction.transaction_id}`
                );
                batch.update(transactionRef, {
                    customer_payment: paymentRef.id,
                    transaction_allocation_status: `Customer Payment - ${this.formatPaymentNumber()}`,
                });
            };
            // 3. Create Journal Entry to reflect accounting changes
            /*    ACCOUNTING LOGIC
                  Once an invoice is allocated, a journal entry will be created
                  so that "Suspense" and "Accounts Receivable" ledgers are
                  updated correctly
            */
            const createJournalEntry = () => {
                entryRef.set({
                    journal_entry_id: entryRef.id,
                    journal_entry_date: transaction.transaction_date,
                    journal_entry_number: this.formatJournalEntryNumber(),
                    closed_off: false,
                    //  Add payment details so that GJE can be deleted if payment is deleted
                    customer_payment: {
                        payment_id: paymentRef.id,
                        payment_number: this.formatPaymentNumber(),
                    },
                    bank_transaction: {
                        transaction_id: transaction.transaction_id,
                        transaction_number: transaction.transaction_number,
                    },
                    line_items: [
                        // Increase "Suspense" Contra
                        {
                            entry_amount: transaction.transaction_amount,
                            line_amount: transaction.transaction_amount,
                            ledger_account: {
                                account_id: "GHxChc2AGkXMiQnSvqNC",
                                account_name: "Suspense Contra (Bank Credits)",
                            },
                        },
                        // Increase "Accounts Receivable Contra"
                        {
                            entry_amount: transaction.transaction_amount,
                            line_amount: transaction.transaction_amount,
                            ledger_account: {
                                account_id: "HXGIZu7HoqPCgVev56Ee",
                                account_name: "Accounts Receivable Contra",
                            },
                        },
                    ],
                    reference_number: this.invoice.invoice_number,
                    supplier: {
                        supplier_id: this.invoice.customer.customer_id,
                        supplier_name: this.invoice.customer.customer_name,
                    },
                });
            };
            // 4. Update Accounting Totals
            const updateAccountingTotals = () => {
                // "Suspense Contra" and "Accounts Receivable Contra" ids
                const accountsToUpdate = [
                    "GHxChc2AGkXMiQnSvqNC",
                    "HXGIZu7HoqPCgVev56Ee",
                ];
                accountsToUpdate.forEach((accountID) => {
                    const accountRef = db
                        .collection("accounting_totals")
                        .doc(transaction.transaction_date.slice(0, 7))
                        .collection("ledger_accounts")
                        .doc(accountID);

                    batch.update(accountRef, {
                        monthly_total: firebase.firestore.FieldValue.increment(
                            transaction.transaction_amount
                        ),
                    });
                });
            };
            updateBankTransaction();
            createJournalEntry();
            updateAccountingTotals();
            //    Increment CPA # and Journal Entry # count
            this.count++;
            this.journal_entry_count++;
        });
        batch
            .commit()
            .then(() => {
                //    Increment CPA # and Journal Entry # count
                this.incrementPaymentCount();
                this.incrementJournalEntryCount();
            })
            .catch((error) => error);
    };
    // 5. Add the payment IDs to the invoice and update values
    const updateInvoiceDetails = () => {
        const batch = db.batch();
        const amountPaid = this.selectedTransactions.reduce(
            (total, item) => total + item.transaction_amount,
            0
        );
        //  Update details on invoice
        batch.update(invoiceRef, {
            // Check if there are already payments
            customer_payments: this.invoice.customer_payments && this.invoice.customer_payments.length > 0 ? this.invoice.customer_payments.concat(customerPaymentsArray) : customerPaymentsArray,
            invoice_amount_paid: firebase.firestore.FieldValue.increment(amountPaid),
            invoice_amount_due: firebase.firestore.FieldValue.increment(-Math.abs(amountPaid)),

        })
        //  Update payment details on original Sales Order
        const salesRef = db.doc(`sales_orders/${this.invoice.sales_order_id}`)
        batch.update(salesRef, {
            customer_payments: this.invoice.customer_payments && this.invoice.customer_payments.length > 0 ? this.invoice.customer_payments.concat(customerPaymentsArray) : customerPaymentsArray,
            order_amount_paid: firebase.firestore.FieldValue.increment(amountPaid),
            order_amount_due: firebase.firestore.FieldValue.increment(-Math.abs(amountPaid)),
        })
        // 5. Close modal
        batch.commit().then(() => closeModal())
            .catch((error) => error);
    };
    const closeModal = () => {
        this.loading = false;
        showSnackbar("Payments allocated successfully!");
        this.passModalClose();
        this.resetValues();
    };
    createReceipt();
    updateInvoiceDetails();
}
/*
        EXPLANATION:
        -   Remove payment link from invoice
        -   Remove payment link from bank transaction
        -   Delete journal entry
        -   Update "Accounting Totals" sub-collection for each line-item
        -   Update invoice amount_paid and amount_due
        -   Delete customer payment from database
*/
export const unallocateCustomerPaymentFromInvoice = function (payment) {
    if (confirm("Are you sure you want to delete this payment?")) {
        // trigger loading state
        this.$set(this.loadDelete, payment.payment_id, true);
        const batch = db.batch();
        //  Object containing all payment info
        //  Used to remove payments from ORDERS && INVOICES
        const paymentObject = {
            payment_amount: payment.payment_amount,
            payment_date: payment.payment_date,
            payment_id: payment.payment_id,
            payment_number: payment.payment_number,
        }
        //  Remove payment link from bank transaction
        //  Set transaction as Unallocated
        const removePaymentFromBankTransaction = () => {
            const transactionRef = db.doc(`bank_transactions/${payment.transaction_id}`)
            batch.update(transactionRef, {
                customer_payment: firebase.firestore.FieldValue.delete(),
                transaction_allocation_status: "No",
            })
        }
        // Delete journal entry
        const deleteJournalEntry = () => {
            const entryRef = db.doc(`general_journal_entries/${payment.entry_id}`)
            batch.delete(entryRef)
        }
        //  Remove payment amounts from "Accounting Totals" ledgers
        const updateAccountingTotals = () => {
            // "Suspense Contra" and "Accounts Receivable Contra" ids
            const accountsToUpdate = [
                "GHxChc2AGkXMiQnSvqNC",
                "HXGIZu7HoqPCgVev56Ee",
            ];
            accountsToUpdate.forEach((accountID) => {
                const accountRef = db
                    .collection("accounting_totals")
                    .doc(payment.payment_date.slice(0, 7))
                    .collection("ledger_accounts")
                    .doc(accountID);

                batch.update(accountRef, {
                    monthly_total: firebase.firestore.FieldValue.increment(
                        -Math.abs(payment.payment_amount)
                    )
                })
            })
        }
        // Remove overpaid amount from Accounting Totals
        const updateOverpaidLedger = () => {
            if (!payment.overpaid_amount) return;
            const ledgerRef = db.doc(`accounting_totals/${payment.payment_date.slice(0, 7)}/ledger_accounts/r0q5ogDJ76WcU4vGGrSb`)
            batch.update(ledgerRef, {
                monthly_total: firebase.firestore.FieldValue.increment(
                    -Math.abs(payment.overpaid_amount)
                )
            })
        }
        //  Delete customer payment from database
        const deleteCustomerPayment = () => {
            const paymentRef = db.doc(`customer_payments/${payment.payment_id}`)
            batch.delete(paymentRef)
        }
        //  Remove customer payment from datatable in Payment Preview UI
        const removePaymentFromTable = () => {
            const paymentIndex = this.customer_payments.findIndex((item) => item.payment_id === payment.payment_id)
            this.customer_payments.splice(paymentIndex, 1)
        }
        //  Test if Payment belongs to a SALES ORDER or an INVOICE
        const testIfInvoiced = () => {
            return payment.invoices ? removePaymentFromInvoice() : removePaymentFromOrder()
        }
        //
        const removePaymentFromOrder = () => {
            //  1. Remove payment link from each sales_order
            //  2. Update sales_order amount_paid && amount_due
            //  3. Test if ORDER with payment has since been invoiced
            //      3.2 Update payment info on Invoice 
            //  4. Commit batch
            //  Remove payment link from each sales_order
            payment.sales_orders.forEach((el, index, array) => {
                const orderRef = db.doc(`sales_orders/${el.order_id}`)
                //  Older payments don't have an "allocated_amount" field
                //  This is fallback for the scenario in which those payments are deleted
                const fallbackPaymentObject = () => {
                    return el.allocated_amount ? { ...paymentObject, allocated_amount: el.allocated_amount } : paymentObject
                }
                const fallbackIncrementAmount = () => {
                    return el.allocated_amount ? el.allocated_amount : payment.payment_amount
                }
                //
                batch.update(orderRef, {
                    customer_payments: firebase.firestore.FieldValue.arrayRemove(fallbackPaymentObject()),
                    order_amount_due: firebase.firestore.FieldValue.increment(
                        fallbackIncrementAmount()
                    ),
                    order_amount_paid: firebase.firestore.FieldValue.increment(
                        -Math.abs(fallbackIncrementAmount())
                    )
                })
                orderRef.get().then((doc) => {
                    // Test if ORDER with a payment has been invoiced
                    const invoiceID = doc.data().invoice_id;
                    if (!invoiceID) return;
                    // Update payment info on Invoice
                    const invoiceRef = db.doc(`invoices/${invoiceID}`)
                    batch.update(invoiceRef, {
                        customer_payments: firebase.firestore.FieldValue.arrayRemove(fallbackPaymentObject()),
                        invoice_amount_due: firebase.firestore.FieldValue.increment(
                            fallbackIncrementAmount()
                        ),
                        invoice_amount_paid: firebase.firestore.FieldValue.increment(
                            -Math.abs(fallbackIncrementAmount())
                        )
                    })
                })
                    // Batch is commited here because we need to wait for the
                    // sales order doc ref to be retreived from the database
                    .then(() => {
                        //  Only commit batch on last iteration of the loop
                        if (index !== array.length - 1) return;
                        batch.commit()
                            .then(() => {
                                this.$set(this.loadDelete, payment.payment_id, false);
                                showSnackbar("Payment deleted successfully")
                            })
                            .catch((error) => error)
                    })
            })
        }
        const removePaymentFromInvoice = () => {
            //  1. Remove payment link from each invoice
            //  2. Update invoices amount_paid && amount_due
            //  3. Update payment info on original sales order
            //  4. Commit batch
            payment.invoices.forEach((el, index, array) => {
                const invoiceRef = db.doc(`invoices/${el.invoice_id}`)
                //  Older payments don't have an "allocated_amount" field
                //  This is fallback for the scenario in which those payments are deleted
                const fallbackPaymentObject = () => {
                    return el.allocated_amount ? { ...paymentObject, allocated_amount: el.allocated_amount } : paymentObject
                }
                const fallbackIncrementAmount = () => {
                    return el.allocated_amount ? el.allocated_amount : payment.payment_amount
                }
                batch.update(invoiceRef, {
                    customer_payments: firebase.firestore.FieldValue.arrayRemove(fallbackPaymentObject()),
                    invoice_amount_due: firebase.firestore.FieldValue.increment(
                        fallbackIncrementAmount()
                    ),
                    invoice_amount_paid: firebase.firestore.FieldValue.increment(
                        -Math.abs(fallbackIncrementAmount())
                    )
                })
                //  Update payment info on original sales order
                const salesRef = db.collection("sales_orders")
                    .where("invoice_id", "==", el.invoice_id)
                    .limit(1)
                salesRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            batch.update(doc.ref, {
                                customer_payments: firebase.firestore.FieldValue.arrayRemove(fallbackPaymentObject()),
                                order_amount_due: firebase.firestore.FieldValue.increment(
                                    fallbackIncrementAmount()
                                ),
                                order_amount_paid: firebase.firestore.FieldValue.increment(
                                    -Math.abs(fallbackIncrementAmount())
                                )
                            })
                        })
                    })
                    // Batch is commited here because we need to wait for the
                    // sales order doc ref to be retreived from the database
                    .then(() => {
                        //  Only commit batch on last iteration of the loop
                        if (index !== array.length - 1) return;
                        batch
                            .commit()
                            .then(() => {
                                this.$set(this.loadDelete, payment.payment_id, false);
                                showSnackbar("Payment deleted successfully")
                            })
                            .catch((error) => error)
                    })

            })
        }
        removePaymentFromBankTransaction();
        deleteJournalEntry();
        updateAccountingTotals();
        updateOverpaidLedger();
        deleteCustomerPayment();
        removePaymentFromTable();
        testIfInvoiced();
    }
}